import { Injectable } from '@angular/core';
import { DownloadConfig } from '@services/core-services/models/download-config';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor(private readonly http: HttpClient) {}

  getAndDownload(url: string, config: DownloadConfig) {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap((data: Blob) => {
        const blob = new Blob([data], { type: config.type || 'application/pdf' }); // Adjust the MIME type based on your file type
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = config.fileName; // Set the desired file name here
        a.click();

        // Clean up: Release the object URL and remove the anchor element
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    );
  }
}
